<script>
    import Input from './Input.svelte';

    export let data = {}, id;
    export let prefix = 'id';
    export let password;

    function validate(value) {
        return (value != password) ? 'Les mots de passe ne correspondent pas' : '';
    }
</script>

<Input {prefix} {id} type="password" {validate} bind:data />
