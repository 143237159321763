<script>
    import { isPromise } from 'lib/utils.js';
    import {createEventDispatcher} from 'svelte';

    export let data = {};
    export let id = null, name = null, prefix = 'id';
    export let type = 'text', placeholder = '';
    export let mask = null, validate = null;
    export let input = null;
    export let display_error = true;

    const dispatch = createEventDispatcher();
    if (! id) {
        id = `${prefix}_${name}`;
    }

    data.setError = () => {
        const value = data.value;
        if (data.disabled) {
            data.error = '';
        } else if (data.required && (value === '' || value === null)) {
            data.error = 'Ce champ est obligatoire';
        } else if (value && validate != null) {
            data.error = validate(value);
            if (isPromise(data.error)) {
                data.error.then((ret) => { data.error = ret });
            }
        } else {
            data.error = '';
        }
    }

    function checkMask(value) {
        if (mask === 'number') {
            return /^-?\d*[.,]?\d*$/.test(value);
        }

        return true;
    }

    function handleInput(ev) {
        const value = ev.target.value;

        if (! checkMask(value)) {
            this.value = data.value;
            return;
        }

        data.value = value;
        data.error = '';
        dispatch('input', {value});
    }

    function handleChange(ev) {
        const value = ev.target.value;
        dispatch('change', {value});
    }
</script>

<input {id} {name} {type} {placeholder} disabled={data.disabled} value={data.value}
       class:border-error={data.error}
       on:blur={() => {setTimeout(data.setError, 200)}}
       on:input={handleInput}
       on:change={handleChange}
       bind:this={input}>

{#if display_error && typeof data.error === 'string' && data.error}
    <span class="oct-info-error">{data.error}</span>
{/if}
