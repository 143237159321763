<script>
    import RadioOption from './RadioOption.svelte';
    export let data = {}, name;
    export let onClick = null;

    data.setError = () => {
        if (data.required && data.value === null) {
            data.error = 'Ce champ est obligatoire';
        } else {
            data.error = '';
        }
    }

    $: if (data.value != null) {
        data.error = '';
    }
</script>

{#each data.options as option}
<RadioOption {name} bind:data value={option[0]} label={option[1]} css="radio" {onClick} />
{/each}

{#if data.error}<span class="oct-info-error">{data.error}</span>{/if}
