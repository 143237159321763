<script>
    import * as http from 'lib/http.js';
    import Modal from '../lib/Modal.svelte';
    import * as utils from 'lib/utils.js';
    import CheckboxGroup from './CheckboxGroup.svelte';
    import Input from './Input.svelte';
    import InputAddress from './InputAddress.svelte';
    import InputConfirmPassword from './InputConfirmPassword.svelte';
    import InputEmail from './InputEmail.svelte';
    import InputPassword from './InputPassword.svelte';
    import InputPostCode from './InputPostCode.svelte';
    import InputSiren from './InputSiren.svelte';
    import InputTel from './InputTel.svelte';
    import RadioBoolean from './RadioBoolean.svelte';
    import Message from './Message.svelte';
    import WelcomeModal from './modals/WelcomeModal.svelte';

    export let signup = false,
               authenticated,
               email = '',
               name = '',
               siren = '',
               address = '',
               postal_code = '',
               first_name = '',
               last_name = '',
               telephone = '',
               telephone_2 = '',
               security_advicer = null,
               merchandise_types = [],
               status = null,
               vehicles_count = 0
               ;
    export let merchandise_type_choices;

    let msg, inputTel, inputTel2;
    let data = {
        user: {
            email: {value: email, required: true},
            password: {value: '', required: true},
            confirm_password: {value: '', required: true},
            first_name: {value: first_name, required: true},
            last_name: {value: last_name, required: true},
            telephone: {value: telephone, required: true},
            telephone_2: {value: telephone_2},
        },
        name: {value: name, required: true, disabled: authenticated},
        siren: {value: siren, required: true, disabled: authenticated},
        address: {value: address, required: true},
        postal_code: {value: postal_code, required: true},
        security_advicer: {value: security_advicer, required: true, disabled: status == 'validated'},
        merchandise_types: {value: merchandise_types, disabled: status == 'validated'},
    };

    $: data.merchandise_types.required = data.security_advicer.value;

    let SAModal = false;
    function confirmCS(ev) {
        ev.preventDefault();
        SAModal = true;
    }

    async function save(ev) {
        ev.preventDefault();

        if (await msg.validate(data)) {
            let body = utils.getFormData(this, {merchandise_types_choices: []});

            let method, url;
            if (authenticated) {
                [method, url] = ['PUT', '/api/my/carrier/'];
            } else {
                [method, url] = ['POST', '/api/create/carrier/'];
            }

            body.user.telephone = inputTel.getNumber()
            body.user.telephone_2 = inputTel2.getNumber()
            //console.log(body);

            http.request(method, url, body).then(
                (json) => {
                    if (signup) {
                        window.location.href = '/signup/step3-vehicles/';
                    } else {
                        msg.success('Changements sauvegardés');
                    }
                },
                (error) => {
                    let non_field_errors = utils.handleServerErrors(error, data);
                    if (non_field_errors.length) {
                        msg.error(non_field_errors[0]);
                    }
                    data = data; // Force update
                }
            );
        }
    }
</script>

<style>
    .oct-form-label {
        clear: both;
        float: left;
        width: 40%;
        font-weight: 600;
        margin-top: 5px;
        font-size: 13px;
    }
</style>


<Message bind:this={msg} />

{#if signup}
<h1>Ma société</h1>
{:else}
<p class="oct-form-global__content-title">Mon identifiant</p>
{/if}

<form class="oct-form" id="oct-form-mon-compte__identifiant" method="post" on:submit={save}>
    <div class="oct-form-fieldset">
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_user.email">Email (identifiant)*</label>
            <div class="oct-form-content">
                <InputEmail name="user.email" bind:data={data.user.email} check_url={true} />
            </div>
        </div>
        <span class="oct-info-error__email"></span>
        {#if ! authenticated}
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_user.password">
                Mot de passe (min. 8 caractères)*
            </label>
            <div class="oct-form-content">
                <InputPassword name="user.password" bind:data={data.user.password} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_confirm_password">
                Confirmer votre mot de passe*
            </label>
            <div class="oct-form-content">
                <InputConfirmPassword id="id_confirm_password" bind:data={data.user.confirm_password}
                                      password={data.user.password.value} />
            </div>
        </div>
        <span class="oct-info-error__mdp"></span>
        {/if}
    </div>

    {#if !signup}
    <p class="oct-form-global__content-title">Fiche entreprise</p>
    <div class="oct-entreprise">
        <span class="oct-entreprise-name">{name}</span>
        {#if status == 'pending'}
            <span class="oct-entreprise-status pending">En attente</span>
        {:else if status == 'validated'}
            <span class="oct-entreprise-status valid">Actif</span>
        {/if}
    </div>
    {/if}
    <div class="oct-form-fieldset">
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_name">
                Dénomination sociale {#if signup}*{/if}
            </label>
            <div class="oct-form-content">
                <Input name="name" bind:data={data.name} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_siren">
                N°Siren {#if signup}(9 chiffres) *{/if}
            </label>
            <div class="oct-form-content">
                <InputSiren name="siren" bind:data={data.siren} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_address">
                Adresse Postale*
                {#if signup}
                <br>
                <span id="description_adresse">
                    Valider dans le menu déroulant <br> après avoir saisi votre adresse
                </span>
                {/if}
            </label>
            <div class="oct-form-content form-field-adresse">
                <InputAddress name="address" bind:data={data.address} bind:postal_code={data.postal_code.value} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_postal_code">Code postal*</label>
            <div class="oct-form-content">
                <InputPostCode name="postal_code" bind:data={data.postal_code} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_user.last_name">Nom*</label>
            <div class="oct-form-content">
                <Input name="user.last_name" bind:data={data.user.last_name} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_user.first_name">Prénom* </label>
            <div class="oct-form-content">
                <Input name="user.first_name" bind:data={data.user.first_name} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_user.telephone">Téléphone mobile *</label>
            <div class="oct-form-content">
                <InputTel name="user.telephone" bind:data={data.user.telephone} bind:this={inputTel}
                          mobile={true} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_user.telephone_2">Téléphone secondaire </label>
            <div class="oct-form-content">
                <InputTel name="user.telephone_2" bind:data={data.user.telephone_2} bind:this={inputTel2} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_security_advicer">
                Conseiller à la sécurité <br/> pour le transport ADR *
            </label>
            <div class="oct-form-field">
                <RadioBoolean name="security_advicer" bind:data={data.security_advicer}
                              onClick={(status == 'validated' || vehicles_count == 0) ? null : confirmCS} />
            </div>
            <div class="oct-form-content">
                <p class="oct_form_field__description">
                    {#if status == 'validated' && ! security_advicer}
                        Pour activer cette option, veuillez nous transmettre
                        votre justificatif « conseiller à la sécurité » en
                        fichier PDF sur contact@express-online.com ainsi que
                        les nouveaux taux kilométriques de vos véhicules afin
                        que nous puissions mettre à jour votre compte
                        transporteur.
                    {:else if data.security_advicer.value}
                        Si vous disposez d’un conseiller à la sécurité pour le
                        transport ADR, vous devrez télécharger en fin
                        d’inscription la copie du certificat de votre
                        conseiller à la sécurité pour le transport ADR et si
                        celui-ci est une personne extérieure à votre
                        entreprise, une attestation de celui-ci indiquant qu’il
                        accepte cette mission ». Enfin pour l’enregistrement de
                        votre véhicule ou de vos véhicules veillez à reporter
                        votre tarif « ADR » et non votre tarif « marchandises
                        générales »
                    {/if}
                </p>
            </div>
        </div>
        {#if data.security_advicer.value}
        <div class="oct-form-group">
            <label class="oct-form-label" for="id_merchandise_types">
                Pour les classes <br/> de marchandises*
            </label>
            <div class="oct-form-content">
                <CheckboxGroup name="merchandise_types_choices" choices={merchandise_type_choices}
                               bind:data={data.merchandise_types} />
            </div>
        </div>
        {/if}

        <p class="form-obligatoire">*champs obligatoires</p>
    </div>

    {#if signup}
    <div class="btn-container">
        <a href="/signup/step1-legal/" class="button left previous">Retour</a>
        <button type="submit" class="button right next">Poursuivre mon inscription</button>
    </div>
    {:else}
    <div class="oct-center">
        <button type="submit" class="button valider">Valider</button>
    </div>
    {/if}
</form>

{#if ! authenticated}
    <WelcomeModal />
{/if}

<Modal title="Conseiller à la sécurité" bind:isOpen={SAModal} cancelText={null} submitText="J'AI COMPRIS"
       on:submit={() => {data.security_advicer.value = ! data.security_advicer.value; SAModal = false;}}>
    Si vous activez cette option, les tarifs kilométriques de vos véhicules
    seront réinitialisés et il vous faudra les vérifier.
</Modal>
