import {scrollToTop} from 'svelte-scrollto';


function doubleClickGuard(fn) {
    let clicked = false;
    function unclick() {
        //console.debug('unclick');
        clicked = false;
    }

    return (...args) => {
        if (clicked) {
            //console.debug('ignore double click');
            return;
        }

        clicked = true;
        return fn(...args, unclick);
    }
}

function isPromise(something) {
    return (!!something && !!something.then && typeof something.then === 'function');
}

function parseNumber(value) {
    if (isNaN(value)) {
        return parseFloat(value.replace(',', '.'));
    }

    return value;
}

function pop(obj, key) { // No pop in JavaScript
    let value = obj[key];
    delete obj[key];
    return value;
}

/*
 * Format related functions
 */

function formatCurrency(value, fractionDigits) {
  if (! value)
    return '';

  let options = {style: 'currency', currency: 'EUR'};
  if (fractionDigits !== undefined) {
    options.minimumFractionDigits = fractionDigits;
    options.maximumFractionDigits = fractionDigits;
  }

  return value.toLocaleString('fr', options);
}

function formatPercent(value) {
    if (! value)
        return '';

    let options = {style: 'percent', minimumFractionDigits: 2};
    return value.toLocaleString('fr', options);
}

/*
 * Error related functions
 */

class ValidationError extends Error {
  constructor(errorCount, ...params) {
    super(...params);

    this.name = 'ValidationError';
    this.errorCount = errorCount;
  }
}

function getError({value, required, error}) {
    if (required && ! value)
        return 'Ce champ est obligatoire.';

    if (error)
        return error;

    return null;
}

function checkErrors(data, submit) {
    let n = 0;
    for (const schema of Object.values(data)) {
        const error = getError(schema);
        if (error) {
            if (submit) {
                n += 1;
                schema.error = error; // Set error
            }
        } else {
            schema.error = null; // Clean error
        }
    }
    return n;
}

function prepareSubmit(data, debug=false) {
    // Validate data
    let errorCount = checkErrors(data, true);
    if (errorCount) {
        throw new ValidationError(errorCount);
    }

    // Prepare data
    let body = {};
    for (const [name, schema] of Object.entries(data)) {
        body[name] = toString(schema.value);
    }

    // Debug
    if (debug) {
        console.log(body);
    }

    return body;
}

function clearErrors(data, key = 'error') {
    for (const [name, value] of Object.entries(data)) {
        delete value[key];
    }
}

function fillErrors(data, errors, key = 'error') {
    for (const [name, value] of Object.entries(errors)) {
        if (Array.isArray(value)) {
            data[name][key] = value[0];
        } else {
            fillErrors(data[name], value);
        }
    }
}

function handleServerErrors(error, data) {
    // This happens when fetch fails (for instance because there's not a
    // network connection)
    if (error.name == 'TypeError') {
        return [
            "Erreur réseaux, merci de vérifier votre connexion à Internet. Si " +
            "l'erreur persiste contactez le service technique."
        ];
    }

    // Unexpected error
    if (error.name != 'ServerError') {
        return [error.message];
    }

    // ServerError
    if (error.status == 500) {
        return [
            "Une erreur est survenue dans le serveur, les équipes techniques ont été " +
            "notifiées, merci de ressayer plus tard. Si l'erreur persiste contactez le support."
        ];
    }

    let json = error.payload;
    if (error.status != 400) {
        return [json.detail || json.non_field_errors || `${error.status} ${error.statusText}`];
    }

    // Validation Error (400)
    if (Array.isArray(json)) {
        return json;
    }

    let non_field_errors = pop(json, 'non_field_errors');
    if (data) {
        fillErrors(data, json);
        if (! non_field_errors && json) {
            non_field_errors = ['Merci de vérifier / remplir les champs en rouge pour continuer.'];
        }
    }

    if (non_field_errors && typeof non_field_errors == 'string') {
        non_field_errors = [non_field_errors];
    }

    return non_field_errors || [];
}


/*
 * Date related functions
 */

function formatDate(value) {
    if (value == null) {
        return '';
    }

    if (typeof value == 'string')
        value = new Date(value);

    return value.toLocaleString('fr', {dateStyle: 'short'});
}

function formatDatetime(value, options = null) {
    if (value == null) {
        return '';
    }

    if (typeof value == 'string')
        value = new Date(value);

    let date = value.toLocaleString('fr', {dateStyle: 'short'});
    let time = value.toLocaleTimeString('fr', {timeStyle: 'short'});
    time = time.replace(':', 'h');

    options = options || {};
    if (options.full) {
        return `le ${date} à ${time}`;
    }
    else if (isSameDay(value, new Date())) {
        return time;
    }
    else {
        return `${date} ${time}`;
    }
}

function get_datetime(date, time1, time2) {
    if (date && time1) {
        return new Date(`${date} ${time1}`);
    } else if (date && time2) {
        return new Date(`${date} ${time2}`);
    }

    return undefined;
}

function get_datetime_str(date, time) {
    return toString(get_datetime(date, time));
}

function get_range_datetime(date_from, hour_from, hour_from_1)
{
    const a = get_datetime(date_from, hour_from);
    const b = get_datetime(date_from, hour_from_1);

    if (a && b) {
        return [a, b];
    }
    else if (a) {
        return [a, newDate(a, 2 * 60 * 60)];
    }
    else if (b) {
        return [newDate(b, -1 * 60 * 60), b];
    }
    else {
        return [undefined, undefined];
    }
}

function isSameDay(date, ref) {
    return (
        date.getDate() == ref.getDate() &&
        date.getMonth() == ref.getMonth() &&
        date.getFullYear() == ref.getFullYear()
    );
}

function loadDate(value) {
    if (typeof value === 'string')
        return new Date(Date.parse(value));

    return value;
}

function newDate(ts, offset, seconds = null) {
    // ts could be a Date object or a unix timestamp in milliseconds
    if (ts instanceof Date) {
        ts = ts.getTime();
    }

    let epoch = ts / 1000; // Convert to seconds
    epoch = epoch + offset;
    if (seconds != null) {
        epoch = epoch - epoch % 60; // Remove seconds
        epoch = epoch + seconds // Add seconds
    }

    return new Date(epoch * 1000);
}

function roundDate(date, seconds) {
  return new Date(Math.round(date / seconds) * seconds);
}

function toString(value) {
    if (value instanceof Date) {
        return value.toISOString().split('.')[0] + 'Z'
    }

    return value;
}

/*
 * Form related functions
 */

function makeTree(data, path, value) {
    name = path.shift();
    name = name.replaceAll('-', '_');
    if (path.length == 0) {
        if (Array.isArray(data[name])) {
            data[name].push(value);
        }
        else {
            data[name] = value;
        }
    }
    else {
        data[name] = data[name] || {};
        makeTree(data[name], path, value);
    }
}

/* Given a form element, return an object, to be sent as JSON. */
function getFormData(form, data = {}) {
    let array = jQuery(form).serializeArray();

    $.map(array, item => {
        let path = item.name.split('.');
        makeTree(data, path, item.value);
    });

    return data;
}


/*
 * Scroll to element
 */
function getPosition(element) {
  let rect = element.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {left: rect.left + scrollLeft, top: rect.top + scrollTop};
}

function scrollTo(element) {
  let offset = getPosition(element).top;
  scrollToTop({offset: offset, duration: 1000});
}

/*
 * For the admin interface
 */

function get_hpm_min_max(time, date_from, hour_from, hour_from_1) {
    const hpm_min = newDate(time, 15*60, 0);  // +15m
    let hpm_max = newDate(time, 40*60, 59); // Hr+40m

    const dt_from_max = get_range_datetime(date_from, hour_from, hour_from_1)[1];
    if (dt_from_max) {
        const b = newDate(dt_from_max, -35*60, 59); // He-35m
        if (b < hpm_max) {
            hpm_max = b;
        }
    }

    return {hpm_min, hpm_max};
}

function get_datetime_from_date_and_time(date_from, hour_from, hour_from_1, date_to, hour_to, hour_to_1) {
    const from_datetime = hour_from ? get_datetime_str(date_from, hour_from) : null;
    const from_datetime_max = hour_from_1 ? get_datetime_str(date_from, hour_from_1) : null;
    const to_datetime = hour_to ? get_datetime_str(date_to, hour_to) : null;
    const to_datetime_max = hour_to_1 ? get_datetime_str(date_to, hour_to_1) : null;
    return {from_datetime, from_datetime_max, to_datetime, to_datetime_max};
}


/*
 * Export
 */
export { formatDate, formatDatetime, formatCurrency, formatPercent};
export { get_datetime, get_datetime_str, loadDate, pop, toString };
export { newDate, roundDate };
export { get_range_datetime };
export { getError, prepareSubmit, clearErrors, fillErrors, handleServerErrors};
export { getFormData };
export { isPromise };
export { scrollTo };
export { get_hpm_min_max, get_datetime_from_date_and_time };
export { doubleClickGuard };
export { parseNumber };
