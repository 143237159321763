<script>
    import { onMount } from 'svelte';
    import Input from './Input.svelte';

    export let data = {}, name;
    export let prefix = 'id';
    export let mobile = false;

    let input;
    let iti;
    onMount(() => {
        iti = window.intlTelInput(input, {
            initialCountry: "fr",
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@21.2.5/build/js/utils.js",
        });
    });

    function validate(value) {
        if (iti.isValidNumber()) {
            if (mobile && iti.getNumberType() != intlTelInputUtils.numberType.MOBILE) {
                return "Le numéro doit être celui d'un téléphone mobile";
            }
            return '';
        }
        const errorCode = iti.getValidationError();
        const errorMap = [
            "Numéro invalide",
            "Code de pays invalide",
            "Trop court",
            "Trop long",
            "Numéro invalide",
        ];
        return errorMap[errorCode] || "Numéro invalide";
    }

    export function getNumber() {
        return iti.getNumber();
    }
</script>

<style>
    :global(div.iti) {
        display: block;
    }
</style>

<Input {prefix} {name} {validate} bind:data bind:input />
