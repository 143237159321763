<script>
    import {onMount} from 'svelte';
    import * as http from 'lib/http.js';
    import Textarea from './Textarea.svelte';
    import Files from './documents_files.svelte';
    import Message from './Message.svelte';
    import NextButton from './buttons/NextButton.svelte';

    export let signup = false;
    export let introduction = '',
               security_advicer = null,
               status;

    let items = null;
    let vehicles = [];
    let msg;
    let data = {
        introduction: {value: introduction, required: true},
    };
    let kbis = false, lt = false, assurance = false, adr = false, tax_cert = false;

    $: ready = (
        kbis && lt && assurance && tax_cert
        && (adr || ! security_advicer)
        && vehicles.every(x => x.grise)
    );

    onMount(() => {
        http.get('/api/my/vehicles/').then((json) => {
            vehicles = json;
        }, (error) => {
        });

        http.get('/api/my/documents/').then((json) => {
            items = json;
        }, (error) => {
        })
    });

    async function save(ev) {
        ev.preventDefault();

        if (await msg.validate(data)) {
            const body = {introduction: data.introduction.value};
            http.put('/api/my/intro/', body).then(
                (json) => { // Success
                    if (signup) {
                        window.location.href = '/signup/done/';
                    } else {
                        msg.success('Changements sauvegardés');
                    }
                },
                (error) => { // Failure
                    console.warn(error);
                }
            );
        }
    }
</script>

<style>
    .oct-form-label {
        clear: both;
        float: left;
        width: 40%;
        font-weight: 600;
        margin-top: 5px;
        font-size: 13px;
    }

    .missing {
        background-color: white;
    }
</style>


<Message bind:this={msg} />

{#if signup}
<h1 class="h1">Mes justificatifs</h1>
{:else}
<p class="oct-form-global__content-title">Mes justificatifs</p>
{/if}

<form id="oct-form-transporteur__justificatif" class="oct-form" method="post" on:submit={save}>
    <div class="oct-form-fieldset">
        <h2 class="bold">Je télécharge mes documents obligatoires</h2>

        {#if items}
        <div class="oct-form-group">
            <span class="oct-form-label">Extrait K Bis de moins de 6 mois*</span>
            <Files {items} {status} type="kbis" bind:ok={kbis} />
        </div>
        <div class="oct-form-group">
            <span class="oct-form-label">Licence de transport LTC ou LTI*</span>
            <Files {items} {status} type="lt" bind:ok={lt} />
        </div>

        <div class="oct-form-group">
            <span class="oct-form-label">Carte grise pour :</span>
        </div>

        {#each vehicles as vehicle}
        <div class="oct-form-group" class:missing={!signup && !vehicle.grise}>
            <span class="oct-form-label" style:padding-left="2em">- le véhicule {vehicle.plate}*</span>
            <Files {items} {status} type="grise" {vehicle} bind:ok={vehicle.grise} />
        </div>
        {/each}

        <div class="oct-form-group">
            <span class="oct-form-label">Attestation d'assurance marchandises transportées*</span>
            <Files {items} {status} type="assurance" bind:ok={assurance} />
        </div>

        <div class="oct-form-group">
            <span class="oct-form-label">Attestation de régularité fiscale*</span>
            <Files {items} {status} type="tax_cert" bind:ok={tax_cert} />
        </div>

        {#if security_advicer}
        <div class="oct-form-group">
            <span class="oct-form-label">
                Attestation de conseiller à la sécurité <br/> pour le transport ADR*
            </span>
            <Files {items} {status} type="adr" bind:ok={adr} />
        </div>
        {/if}
        {/if}

        <p class="text-information-justificatif">
            <span class="italic">Chaque document doit avoir une taille inférieure à 5 Mo,
            les formats admis sont : .pdf .jpeg, .jpg.</span>
        </p>
        <div class="oct-form-group">
            <label class="oct-form-label orange" for="id_introduction">
                Présentation de mon entreprise*
            </label>
            <div class="oct-form-content">
                <Textarea name="introduction" bind:data={data.introduction} />
            </div>
        </div>
        <div class="oct-form-group">
            <div class="oct-label-inforamtions-complementaires ">
                Si vous avez des difficultés à télécharger vos pièces jointes <br/>
                vous pouvez contacter nos équipes opérationnelles au 01 47 21 44 44
            </div>
        </div>
        <p class="form-obligatoire">* Téléchargements et champs obligatoires</p>
    </div>

    <div class="btn-container">
        {#if signup}
            <a href="/signup/step4-drivers/" class="button left previous">Retour</a>
            {#if ready}
                <NextButton type="submit" text="Terminer mon inscription" />
            {:else}
                <NextButton type="submit" text="Terminer mon inscription" disabled
                            title="Pour terminer votre inscription, veuillez fournir les documents demandés" />
            {/if}
        {:else}
            {#if ready}
                <button type="submit" class="button valider">Valider</button>
            {:else}
                <button type="submit" class="button valider" disabled
                        title="Pour valider, veuillez fournir les documents demandés">
                    Valider
                </button>
            {/if}
        {/if}
    </div>

</form>
