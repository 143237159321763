<script>
    import {onMount} from 'svelte';
    import 'geocomplete';
    import Input from './Input.svelte';

    export let data = {}, name;
    export let prefix = 'id';

    // on:change is called first, then on:blur, and finally geocode:result
    // So first we clear the value, then on:blur will set the error but with a
    // timer, so data.value will actually be set before the error.
    // This is hackish, we could do better if we had more control over
    // geocomplete.

    function handleChange(ev) {
        data.value = '';
    }

    // Used to update postal code in parent component
    export let postal_code = '';
    let input_postal_code;
    let update_postal_code = true;
    function geocode_result(ev, result) {
        data.value = result.formatted_address;
        data.error = ''; // Clear error
        if (update_postal_code)
            postal_code = input_postal_code.value;

        update_postal_code = true;
    }

    // Geocomplete
    let input, map, details;
    onMount(() => {
        const country = (
            'AND,BEL,CHE,ESP,FRA,GIB,ITA,LIE,LUX,MCO,MLT,NLD,PRT,RSM,VAT,' // West
            + 'DNK,EST,FIN,FRO,GGY,GBR,IMN,IRL,ISL,JEY,LTU,LVA,NOR,SWE,' // North
            + 'ALB,AUT,BGR,BIH,CZE,GRC,DEU,HUN,HRV,MKD,MNE,ROU,SRB,SVK,SVN,' // Middle
            + 'ARM,AZE,BLR,CYP,GEO,KAZ,MDA,POL,RUS,TUR,UKR' // East
        ).split(',');
        const options = {
            map,
            componentRestrictions: {country},
            details,
            detailsAttribute: 'data-geo'};
        const j_input = jQuery(input).geocomplete(options).bind("geocode:result", geocode_result);
        if (data.value) {
            update_postal_code = false;
            j_input.trigger('geocode');
        }
    });
</script>


<Input {prefix} {name} bind:data bind:input on:change={handleChange} />
<div id="pickup-geocomplete-map" bind:this={map}></div>
<div bind:this={details}>
    <input type="hidden" data-geo="location" name="location">
    <input type="hidden" data-geo="street_number" name="street_number">
    <input type="hidden" data-geo="route" name="route">
    <input type="hidden" data-geo="locality" name="town">
    <input type="hidden" data-geo="postal_code" bind:this={input_postal_code}>
    <input type="hidden" data-geo="country" name="country">
    <input type="hidden" data-geo="administrative_area_level_1" name="state">
    <input type="hidden" data-geo="administrative_area_level_2" name="county">
</div>
