<script>
    export let choices, name;
    export let data;

    data.setError = () => {
        if (data.required && data.value.length == 0) {
            data.error = 'Ce champ est obligatoire';
        } else {
            data.error = '';
        }
    }
</script>

{#each choices as choice}
    <label style:width="100%">
        <input type="checkbox" {name} value={choice[0]} bind:group={data.value} disabled={data.disabled}>
        {choice[1]}
    </label>
{/each}

{#if data.error}<span class="oct-info-error">{data.error}</span>{/if}
