<script>
    import Input from './Input.svelte';

    export let data = {}, name;
    export let prefix = 'id';

    function validate(value) {
        value = value.trim();
        return (value.length != 5) ? 'Code postal invalide' : '';
    }
</script>


<Input {prefix} {name} {validate} bind:data />
