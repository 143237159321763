<script>
    import {onMount} from 'svelte';
    import * as http from 'lib/http.js';
    import * as utils from 'lib/utils.js';
    import Form from './drivers_form.svelte';
    import NextButton from './buttons/NextButton.svelte';

    const url = '/api/my/drivers/';
    export let signup = false;

    let items = [];
    let dirty = false;
    let loaded = false;
    let newItem = false;
    let table = null;

    onMount(() => {
        dirty = true;
    });

    $: if (dirty) {
        http.get(url).then((json) => {
            items = json;
            if (table) {
                utils.scrollTo(table);
            }
            dirty = false;
            loaded = true;
        }, (error) => {
            dirty = false;
        })
    }

    function remove(item) {
        http.delete_(url + item.id + '/').then((response) => {
            dirty = true;
        }, (error) => {
            console.log('ERROR');
        });
    }
</script>


{#if signup}
<h1>Mes chauffeurs</h1>
{:else}
<p class="oct-form-global__content-title">Mes chauffeurs</p>
{/if}

{#if loaded}

{#if items.length}
<table class="oct-table-resultat-container" bind:this={table}>
    <tr>
        <th>Prénom</th>
        <th class="oct-chauffeur-telephone">Téléphone</th>
        <th class="oct-chauffeur-email">Email</th>
        <th>Droits d’accès</th>
        <th>Action</th>
    </tr>
    {#each items as item, idx}
    <tr class="oct-table-item">
        <td>{item.first_name}</td>
        <td class="oct-chauffeur-telephone">{item.telephone}</td>
        <td class="oct-chauffeur-email">{item.email}</td>
        <td>{item.role == 'admin' ? 'Admin' : 'Chauffeur'}</td>
        <td class="oct-table-item_actions">
            {#if item.role == 'driver'}
            <span class="oct-table-liste__edit" on:click={() => {item.edit = ! item.edit}}>
                <i class="fas fa-pencil-alt"></i>
            </span>
            <span on:click={() => remove(item)}>
                <i class="fas fa-trash-alt"></i>
            </span>
            {/if}
        </td>
    </tr>
    {#if item.edit}
    <tr class="oct-table-form-edit">
        <td colspan="5">
            <div class="oct-table-form-edit__content">
                <Form {idx} method="PUT" url={url + item.id + '/'} {...item}
                      on:save={() => {dirty = true; item.edit = false}} />
            </div>
        </td>
    </tr>
    {/if}
    {/each}
</table>
{:else}
    <p class="bold only-content">
        Vous n’avez pas déclaré de chauffeur pour l’instant. Vous pouvez
        créeer un compte par chauffeur qui lui permettra de télécharger
        l’application mobile et de recevoir les informations sur les
        missions qui lui sont confiées.
    </p>
{/if}

{#if items.length == 0 || newItem}
    <div class="oct-fieldset">
        <Form idx={items.length} {url} on:save={() => {dirty = true; newItem = false}} />
    </div>
{:else}
    <div class="oct-form-fieldset oct-form-fieldset--add_button">
        <button class="button plus" on:click={() => {newItem = true}}>Nouveau chauffeur</button>
    </div>
{/if}

{#if signup}
<div class="btn-container">
    <a href="/signup/step3-vehicles/" class="button left previous">Retour</a>
    {#if items.length}
        <NextButton href="/signup/step5-documents/" />
    {/if}
</div>
{/if}

{:else}

<div class="loader"></div>

{/if}
