<script>
    import * as http from 'lib/http.js';
    import Input from './Input.svelte';

    export let data = {}, name;
    export let prefix = 'id';
    export let check_url = false;

    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let dup = false;

    function validate(value) {
        dup = false;
        if (! re.test(value))
            return 'Email incorrect';

        if (! check_url)
            return '';

        return http.get(`/api/check/email/${value}/`).then((json) => {
            dup = true
            return true; // Signals error but doesn't display anything
        }, (error) => {
            return (error.status == 404) ? '' : 'Network error';
        });
    }
</script>

<Input {prefix} {name} {validate} bind:data />

{#if dup}
    <p>
    Désolé, cette adresse e-mail est déjà inscrite, merci de vous <a
    href="/accounts/login/" class="orange">connecter</a> ou de nous <a
    href="/contact/" class="orange">contacter</a> si vous rencontrez un
    problème.
    </p>
    <p>
    Vous pouvez ré-initialiser votre mot de passe
    <a href="/accounts/password_reset/" class="orange">ici</a>.
    </p>
{/if}
