<script>
    export let data = {}, name, prefix = 'id';

    const id = `${prefix}_${name}`;

    data.setError = () => {
        const value = data.value;
        if (data.required && (value === '' || value === null)) {
            data.error = 'Ce champ est obligatoire';
        } else {
            data.error = '';
        }
    }

    function handleInput(ev) {
        const value = ev.target.value;

        data.value = value
        data.error = '';
    }
</script>

<textarea {id} {name} class:border-error={data.error} on:blur={data.setError} on:input={handleInput}
>{data.value}</textarea>

{#if data.error}<span class="oct-info-error">{data.error}</span>{/if}
