<script>
    import {onMount} from 'svelte';
    import * as http from 'lib/http.js';
    import * as utils from 'lib/utils.js';
    import Form from './vehicles_form.svelte';
    import Message from './Message.svelte';
    import NextButton from './buttons/NextButton.svelte';

    const url = '/api/my/vehicles/';
    export let signup = false, tvdata, security_advicer, carrier_status;

    let items = [];
    let dirty = false;
    let loaded = false;
    let newItem = false;
    let table = null;
    let tooltip = null;
    let tkm_is_ok = null;
    let msg;

    onMount(() => {
        dirty = true;
    });

    $: if (dirty) {
        http.get(url).then((json) => {
            items = json;
            items.forEach(item => {
                item.TKMmin = (item.TKMmin) ? (item.TKMmin / 100): item.TKMmin;
            });
            tkm_is_ok = items.every(item => item.tkm_is_ok);
            if (table) {
                utils.scrollTo(table);
            }
            dirty = false;
            loaded = true;
        }, (error) => {
            console.warn(error);
            dirty = false;
        })
    }

    function remove(id) {
        http.delete_(url + id + '/').then((response) => {
            dirty = true;
        }, (error) => {
            console.log('ERROR');
        });
    }

    function handleSave(ev) {
        dirty = true;
        if (carrier_status == 'validated' && ! ev.detail.active) {
            msg.warning(
                "Pour que nous puissions activer votre véhicule, vous devez impérativement nous fournir une copie de la carte grise dans l'onglet <a href='/profile/documents'>MES JUSTIFICATIFS</a>"
            );
        }
        else {
            msg.clear();
        }
    }
</script>

<style>
.cannot-continue {
    color: red;
    font-style: italic;
    display: inline-block;
    width: 18em;
}

.oct-table-item .oct-menu__notification {
    font-size: 12px;
    line-height: 15px;
    height: 15px;
    width: 15px;
}

.vehicle-inactive {
    cursor: default;
}

.vehicle-inactive:hover {
    color: #999999;
}
</style>

{#if signup}
    <h1>Mes véhicules <span class="subtitle">Au delà de 5 véhicules, nous consulter</span></h1>
{:else}
    <p class="oct-form-global__content-title">Mes véhicules</p>
{/if}

<Message bind:this={msg} />

{#if loaded}

{#if items.length}
<table id="oct-table-liste" class="oct-table-resultat-container" bind:this={table}>
    <tr>
        <th>Marque et modèle</th>
        <th>Catégorie</th>
        <th class="oct-vehicule-immatriculation">Immatriculation</th>
        <th class="uppercase">tkm</th>
        <th>Action</th>
    </tr>
    {#each items as item, idx}
    <tr class="oct-table-item">
        <td>{item.model}</td>
        <td>{tvdata[item.tv].label}</td>
        <td class=" oct-vehicule-immatriculation uppercase">{item.plate}</td>
        <td>
            {#if ! item.tkm_is_ok}
                <span class="oct-menu__notification">!</span>
            {/if}
            <span class:red={! item.tkm_is_ok}>
                {#if item.TKMmin != null}{item.TKMmin.toLocaleString('fr')}{:else}{tvdata[item.tv].tkm_default}{/if}
            </span>
            <div class="table_tooltip__division">
                <img class="img-puce-tkm" src="/static/public/img/puce_info.png" alt="puce info"
                     data-toggle="tooltip" title="km approche + km en charge + km retour"
                     on:click={() => {tooltip = (tooltip == item) ? null : item}}>
                {#if tooltip === item}
                <div class="tooltip__container">
                    <div class="tooltip__content">
                        km approche + km en charge + km retour
                        <br>
                        en tenant compte des caractéristiques de votre
                        entreprise et de votre véhicule (conseiller à la
                        sécurité ADR, catégorie de véhicule, frigorifique,
                        hayon élévateur...).
                    </div>
                </div>
                {/if}
            </div>
        </td>
        <td class="oct-table-item_actions">
            <span data-id="1" class="oct-table-liste__edit" on:click={() => {item.edit = ! item.edit}}>
                <i class="fas fa-pencil-alt"></i>
            </span>
            <span data-id="1" on:click={(e) => remove(item.id)}>
                <i class="fas fa-trash-alt"></i>
            </span>
            {#if ! item.active}
                <span class="vehicle-inactive" title="Inactif">
                    <i class="fas fa-hourglass-start"></i>
                </span>
            {/if}
        </td>
    </tr>
    {#if item.edit}
    <tr class="oct-table-form-edit">
        <td colspan="5">
            <Form {idx} method="PUT" url={url + item.id + '/'} {security_advicer}
                  {...item} on:save={(ev) => {handleSave(ev); item.edit = false}} />
        </td>
    </tr>
    {/if}
    {/each}
</table>
{/if}

{#if items.length == 0}
    <Form title="Véhicule 1*" {url} {security_advicer} on:save={handleSave} />
{:else if items.length < 5}
    {#if newItem}
        <Form title="Nouveau vehicule*" {url} {security_advicer}
              on:save={(ev) => {handleSave(ev); newItem = false}} />
    {:else}
        <div class="oct-form-fieldset oct-form-fieldset--add_button">
            <button class="button plus" on:click={() => {newItem = true}}>Nouveau véhicule</button>
        </div>
    {/if}
{:else}
    <p>Vous pouvez gérer maximum 5 véhicules. Si vous avez plus de véhicules, merci de nous
    contacter par mail à <a href="mailto:contact@perceval.fr">contact@perceval.fr</a></p>
{/if}

{#if signup}
<div class="btn-container">
    <a href="/signup/step2-company/" class="button left previous">Retour</a>
    {#if items.length == 0}
        <NextButton disabled title="Pour poursuivre votre inscription, veuillez renseigner au moins 1 véhicule" />
    {:else if ! tkm_is_ok}
        <NextButton disabled>
            <br>
            <span class="cannot-continue">
                <strong>Attention, pour pouvoir continuer l'inscription,</strong>
                merci de modifier les taux km de référence (TKM) de vos véhicules
                qui ne sont actuellement pas dans les limites autorisées.
            </span>
        </NextButton>
    {:else}
        <NextButton href="/signup/step4-drivers/" />
    {/if}
</div>
{/if}

{:else}

<div class="loader"></div>

{/if}
