<script>
    import { createEventDispatcher, onMount } from 'svelte';

    const dispatch = createEventDispatcher();

    export let title = null;
    export let isOpen;
    export let cancelText = "Annuler",         // Default behaviour
               submitText = "Sauvegarder";
    export let draggable = true,               // Options
               position = { my: "center", at: "center", of: window },
               width = 300,
               classes = {
                   "ui-dialog-content": "flex-center",
               };

    const jQ = window.$;
    let modal;

    function submit() {
        dispatch('submit');
    }

    let buttons = [];
    if (cancelText)
        buttons.push({text: cancelText, click: close});
    if (submitText)
        buttons.push({text: submitText, click: submit});

    onMount(() => {
        jQ(modal).dialog({
            autoOpen: false,
            buttons: buttons,
            hide: true,
            minHeight: 200,
            minWidth: 200,
            modal: true,
            resizable: false,
            show: true,
            close,
            open,
            // More options
            classes,
            draggable,
            position,
            width,
        });
    });

    $: if (isOpen) {
        jQ(modal).dialog('open');
    } else {
        jQ(modal).dialog('close');
    }

    export function open(event, ui) {
        const ctrl = event.target.querySelector('input');
        if (ctrl)
            ctrl.focus();
    }

    export function close() {
        jQ(modal).dialog('close');
        isOpen = false;
        dispatch('close');
    }
</script>

<div bind:this={modal} {title}>
    <slot></slot>
</div>
