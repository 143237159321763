<script>
    import {createEventDispatcher} from 'svelte';
    import * as http from 'lib/http.js';
    import * as utils from 'lib/utils.js';
    import Input from './Input.svelte';
    import InputEmail from './InputEmail.svelte';
    import InputTel from './InputTel.svelte';
    import Message from './Message.svelte';

    const dispatch = createEventDispatcher();

    export let idx;
    export let url;
    export let method = 'POST';
    export let first_name = '',
               telephone = '',
               email = '';

    const prefix = (idx === null) ? 'id' : `id_${idx}`;
    let email_error = false;

    let msg, inputTel;
    let data = {
        first_name: {value: first_name, required: true},
        telephone: {value: telephone, required: true},
        email: {value: email, required: true},
    };

    async function save(ev) {
        ev.preventDefault();

        if (await msg.validate(data)) {
            let body = utils.getFormData(this);
            http.request(method, url, body).then(
                (json) => { // Success
                    dispatch('save', {});
                },
                (error) => { // Failure
                    let non_field_errors = utils.handleServerErrors(error, data);
                    if (non_field_errors.length) {
                        msg.error(non_field_errors[0]);
                    }
                    data = data; // Force update
                }
            );
        }
    }
</script>


<form class="oct-form oct-form-transporteur__chauffeur" method="post" on:submit={save}>
    <Message bind:this={msg} />

    <div class="oct-form-fieldset">
        <p class="h1">Chauffeur {idx + 1}</p>
        <div class="oct-form-group">
            <label class="oct-form-label" for="{prefix}_first_name">Prénom*</label>
            <div class="oct-form-content">
                <Input {prefix} name="first_name" bind:data={data.first_name} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="{prefix}_telephone">Numéro de téléphone mobile*</label>
            <div class="oct-form-content">
                <InputTel {prefix} name="telephone" bind:data={data.telephone} bind:this={inputTel}
                          mobile={true} />
            </div>
        </div>
        <div class="oct-form-group">
            <label class="oct-form-label" for="{prefix}_email">Email*</label>
            <div class="oct-form-content">
                <InputEmail {prefix} name="email" bind:data={data.email} bind:error={email_error} />
            </div>
        </div>
        {#if email_error}
        <span class="oct-info-error__email">
            Email incorrect
        </span>
        {/if}
        <input type="hidden" name="role" value="driver">
        <p><br>(ses identifiants et le lien pour télécharger l’appli mobile lui seront envoyés par mail et SMS)</p>
        <p class="form-obligatoire">*Champs sont obligatoires</p>
        <div class="oct-center">
            <button id="btn-add-vehicule" class="button center">Valider</button>
        </div>
    </div>
</form>
