<script>
    import * as utils from 'lib/utils.js';

    let level = null, text = '', div = null;

    export const clear = () => {
        level = null;
        text = '';
    }

    export const error = (value) => {
        level = 'error';
        text = value;
    }

    export const warning = (value) => {
        level = 'warning';
        text = value;
    }

    export const success = (value) => {
        level = 'success';
        text = value;
    }

    export async function validate(data) {
        text = '';
        let err = false;

        for (const [name, schema] of Object.entries(data)) {
            schema.setError && schema.setError();
            await schema.error;
            if (schema.error)
                err = true;

            // For nested data
            if (! schema.hasOwnProperty('value')) {
                if (! await validate(schema))
                    err = true;
            }
        }

        if (err) {
            error('Merci de vérifier / remplir les champs en rouge pour continuer.');
            return false;
        }

        return true;
    }

    // Scroll to message
    $: if (div && text) {
        utils.scrollTo(div);
    }
</script>

{#if text}
<div id="oct-alert" class="oct-alert oct-alert_{level}" bind:this={div}>{@html text}</div>
{/if}
