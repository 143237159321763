<script>
    import * as http from 'lib/http.js';
    import Input from './Input.svelte';

    export let data = {}, name;
    export let prefix = 'id';

    let dup = false;

    function validate(value) {
        dup = false;
        if (value.length != 9) {
            return 'Longueur incorrecte';
        }

        return http.get(`/api/check/siren/${value}/`).then((json) => {
            dup = true
            return true; // Signals error but doesn't display anything
        }, (error) => {
            return (error.status == 404) ? '' : 'Network error';
        });
    }
</script>

<Input {prefix} {name} {validate} bind:data />

{#if dup}
    <p>
    Désolé, votre société est déjà inscrite, merci de vous
    <a href="/accounts/login/" class="orange">connecter</a> ou de nous
    <a href="/contact/" class="orange">contacter</a> si vous rencontrez un
    problème.
    </p>
    <p>
    Vous pouvez ré-initialiser votre mot de passe
    <a href="/accounts/password_reset/" class="orange">ici</a>.
    </p>
{/if}
