class ServerError extends Error {
  constructor(status, statusText, payload, ...params) {
    super(...params);

    this.name = 'ServerError';
    this.status = status;
    this.statusText = statusText;
    this.payload = payload;
  }
}


function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


function cleanParams(params) {
  let clean = {};
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value) && value.length == 0) {
      continue;
    }

    if (value == undefined) {
      continue;
    }

    clean[key] = value;
  }

  return clean;
}

async function get(url, params = null, controller = null) {
    if (params) {
        params = cleanParams(params);
        let base = window.location;
        url = new URL(url, base);
        for (const [key, value] of Object.entries(params)) {
            url.searchParams.append(key, value);
        }
    }

    // Request
    let options = {};
    if (controller) {
        options.signal = controller.signal;
    }
    const response = await fetch(url, options);

    // Read payload
    const contentType = response.headers.get('Content-Type');
    let payload;

    if (contentType == 'application/json') {
        payload = await response.json();
    } else {
        payload = await response.text();
    }

    // Forward errors
    if (! response.ok) {
        throw new ServerError(response.status, response.statusText, payload);
    }

    // Ok
    return payload;
}


async function request(method, url, body, contentType = 'application/json') {
  // Prepare options
  const csrf = getCookie('csrftoken');
  let headers = {'X-CSRFToken': csrf};
  let options = {method: method, headers: headers};

  if (body != undefined) {
    if (contentType == 'application/json') {
      body = cleanParams(body);
      body = JSON.stringify(body);
    }

    if (contentType) {
      headers['Content-Type'] = contentType;
    }

    options.body = body;
  }

  // Request
  const response = await fetch(url, options);
  contentType = response.headers.get('Content-Type');

  // Read payload
  let payload;
  if (contentType == 'application/json') {
    payload = await response.json();
  } else {
    payload = await response.text();
  }

  // Forward errors
  if (! response.ok) {
    throw new ServerError(response.status, response.statusText, payload);
  }

  // Ok
  return payload;
}


async function patch(url, body) {
  return request('PATCH', url, body);
}

async function post(url, body, contentType = 'application/json') {
  return request('POST', url, body, contentType);
}

async function put(url, body) {
  return request('PUT', url, body);
}

async function delete_(url) {
  return request('DELETE', url);
}


async function wrapper(payload) {
  return payload;
}

function poll(timeout, url, callback) {
  function timer() {
    get(url).then((payload) => {
      let promise = wrapper(payload);
      callback(promise);
    });
  };

  setTimeout(timer, timeout * 1000);
  return '';
}

export { getCookie, request, get, delete_, patch, post, put, poll };
