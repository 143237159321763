<script>
    export let data = {}, name;
    export let css = null;
    export let value, label;
    export let onClick = null;
</script>

<label class={css} class:border-error={data.error} class:disabled={data.disabled}>
    <input type="radio" {name} {value} disabled={data.disabled} bind:group={data.value}
           on:click={onClick}>
    <span>{label}</span>
</label>
