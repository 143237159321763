<script>
    import { onMount } from 'svelte';
    import Input from './Input.svelte';

    export let data = {}, name;
    export let prefix = 'id';

    let input;
    onMount(() => {
        jQuery.datepicker.setDefaults(jQuery.datepicker.regional.fr);
        jQuery(input).datepicker({
            changeMonth: true,
            changeYear: true,
            onSelect: function(date, datepicker) {
                data.value = date;
            }
        });
    })
</script>

<Input {prefix} {name} bind:data bind:input />
