<script>
    import {round} from 'lodash-es';
    import {createEventDispatcher} from 'svelte';
    import * as http from 'lib/http.js';
    import * as utils from 'lib/utils.js';
    import Input from './Input.svelte';
    import InputDate from './InputDate.svelte';
    import InputTKM from './InputTKM.svelte';
    import Radio from './Radio.svelte';
    import RadioBoolean from './RadioBoolean.svelte';
    import Message from './Message.svelte';

    const dispatch = createEventDispatcher();

    export let security_advicer;
    export let title = '';
    export let url;
    export let method = 'POST';
    export let idx = null;
    export let active = false,
               model = '',
               plate_date = '',
               plate = '',
               tv = null,
               removable_tarp = null,
               panel = null,
               fridge = null,
               tail_board = null,
               payload = null,
               length = null,
               width = null,
               height = null,
               adr = null,
               long_distance = null,
               short_distance = null,
               TKMmin = null,
               TKMmin_dontknow = false;

    const prefix = (idx === null) ? 'id' : `id_${idx}`;
    const tv_options = [
        [1, '2 m3'],
        [2, '5 m3'],
        [3, '10 m3'],
        [4, '14 m3'],
        [5, '20 m3'],
        [6, 'PL > 3T5'],
    ];

    let msg;
    let data = {
        model: {value: model, required: true},
        plate_date: {value: plate_date, required: true, disabled: active},
        plate: {value: plate, required: true, disabled: active},
        tv: {value: tv, required: true, options: tv_options},
        removable_tarp: {value: removable_tarp, required: true},
        panel: {value: panel, required: true},
        fridge: {value: fridge, required: true},
        tail_board: {value: tail_board, required: true},
        payload: {value: payload, required: true},
        length: {value: length, required: true},
        width: {value: width, required: true},
        height: {value: height, required: true},
        adr: {value: adr, required: true},
        long_distance: {value: long_distance, required: true},
        short_distance: {value: short_distance, required: true},
        TKMmin: {value: TKMmin, required: true},
        TKMmin_dontknow: {value: TKMmin_dontknow},
    };

    let error = false;

    $: data.TKMmin.required = ! data.TKMmin_dontknow.value;

    let tkm_min = null, tkm_max = null, tkm_default = '';
    $: if (data.tv.value) {
        const params = {
            tv: data.tv.value,
            removable_tarp: data.removable_tarp.value,
            panel: data.panel.value,
            fridge: data.fridge.value,
            tail_board: data.tail_board.value,
            adr: data.adr.value,
            security_advicer: security_advicer,
        };
        http.get('/api/perceval/tkm/', params).then((json) => {
            tkm_default = json.default;
            tkm_min = json.min;
            tkm_max = json.max;
        }, (error) => {
        });
    }

    // Vérification Form Véhicule (STEP 3 : Inscription Transporteurs // Mon compte : mes véhicules)
    async function save(ev) {
        ev.preventDefault();

        if (await msg.validate(data)) {
            let body = utils.getFormData(this);

            // TKMmin
            if (body.TKMmin_dontknow) {
                body.TKMmin = 0;
            } else {
                body.TKMmin = parseFloat(body.TKMmin.replace(',', '.'));
                body.TKMmin = Math.round(body.TKMmin * 100); // The API takes cents
                body.TKMmin_dontknow = false; // Send false if not checked
            }

            http.request(method, url, body).then(
                (json)  => {
                    dispatch('save', json);
                },
                (error) => {
                    let non_field_errors = utils.handleServerErrors(error, data);
                    if (non_field_errors.length) {
                        msg.error(non_field_errors[0]);
                    }
                    data = data; // Force update
                }
            );
        }
    }
</script>


<form class="oct-form tableau-form" id="oct-form-transporteur__vehicule" method="post" on:submit={save}>
    <Message bind:this={msg} />

    <div class="oct-form-fieldset">
        <p class="h1">{title}</p>
        <p class="h2">Carte grise à télécharger en fin d’inscription </p>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="{prefix}_model">Marque & modèle</label>
            <div class="oct-form-content">
                <Input name="model" bind:data={data.model} {prefix} />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="{prefix}_plate_date">Date de 1ère mise en circulation</label>
            <div class="oct-form-content">
                <InputDate name="plate_date" bind:data={data.plate_date} {prefix} />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="{prefix}_plate">Immatriculation</label>
            <div class="oct-form-content">
                <Input name="plate" bind:data={data.plate} {prefix} />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="oct-form-transporteur__vehicule-categorie">Catégorie</label>
            <div class="oct-form-field oct-form-content" id="oct-form-transporteur__vehicule-categorie">
                <Radio name="tv" bind:data={data.tv} />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="oct-form-transporteur__vehicule-debachable">Débâchable</label>
            <div class="oct-form-field" id="oct-form-transporteur__vehicule-debachable">
                <RadioBoolean name="removable_tarp" bind:data={data.removable_tarp} />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="oct-form-transporteur__vehicule-tole">Tôlé</label>
            <div class="oct-form-field" id="oct-form-transporteur__vehicule-tole">
                <RadioBoolean name="panel" bind:data={data.panel} />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="oct-form-transporteur__vehicule-frigorifique">Frigorifique</label>
            <div class="oct-form-field" id="oct-form-transporteur__vehicule-frigorifique">
                <RadioBoolean name="fridge" bind:data={data.fridge} />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="oct-form-transporteur__vehicule-hayon-elevateur">
                Hayon élévateur
            </label>
            <div class="oct-form-field" id="oct-form-transporteur__vehicule-hayon-elevateur">
                <RadioBoolean name="tail_board" bind:data={data.tail_board} />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="{prefix}_payload">Charge Utile (kg)</label>
            <div class="oct-form-content">
                <Input name="payload" bind:data={data.payload} {prefix} mask="number" />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="{prefix}_length">Longueur de chargement (cm)</label>
            <div class="oct-form-content">
                <Input name="length" bind:data={data.length} {prefix} mask="number" />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="{prefix}_width">Largeur au passage de roues (cm)</label>
            <div class="oct-form-content">
                <Input name="width" bind:data={data.width} {prefix} mask="number" />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="{prefix}_height">Hauteur au passage de porte (cm)</label>
            <div class="oct-form-content">
                <Input name="height" bind:data={data.height} {prefix} mask="number" />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="oct-form-transporteur__vehicule-formation-adr">
                Formation "ADR colis" du conducteur
            </label>
            <div class="oct-form-field" id="oct-form-transporteur__vehicule-formation-adr">
                <RadioBoolean name="adr" bind:data={data.adr} />
            </div>
            {#if security_advicer}
            <span class="info_label">
            Attention, vous êtes Conseillé à la Sécurité donc normalement vos
            véhicules sont ADR et vous devrez télécharger votre justificatif en
            fin d'inscription.
            </span>
            {/if}
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="oct-form-transporteur__vehicule-longue-distance">
                Longue distance
            </label>
            <div class="oct-form-field" id="oct-form-transporteur__vehicule-longue-distance">
                <RadioBoolean name="long_distance" bind:data={data.long_distance} />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label class="oct-form-label" for="oct-form-transporteur__vehicule-regional">Régional</label>
            <div class="oct-form-field" id="oct-form-transporteur__vehicule-regional">
                <RadioBoolean name="short_distance" bind:data={data.short_distance} />
            </div>
        </div>
        <div class="oct-form-group vehicule">
            <label for="{prefix}_TKMmin">
                Taux km de référence pour 600 km
                <span class="info_label">
                    {#if data.TKMmin.value}
                        Avec ce taux kilométrique une course de 600 km (km
                        approche + km en charge + km retour) vous sera payée
                        <b>{Math.round(utils.parseNumber(data.TKMmin.value) * 600)}</b> €
                    {:else}
                        (km approche + km en charge + km retour)
                    {/if}
                </span>
            </label>
            <div class="oct-form-field taux-kilometriques-container">
                <InputTKM name="TKMmin" {prefix} {tkm_min} {tkm_max} {tkm_default}
                          bind:data={data.TKMmin} bind:dontknow={data.TKMmin_dontknow} />
                {#if tkm_default && !data.TKMmin.error && utils.parseNumber(data.TKMmin.value) > tkm_default * 1.05}
                <p class="oct-info-error">Attention votre taux kilométrique est
                plus cher que la moyenne des véhicules d’Express-Online !</p>
                {/if}
            </div>
        </div>
        <p class="form-obligatoire">*Tous les champs sont obligatoires</p>
        <div class="oct-center">
            <button id="btn-add-vehicule" class="button center">Valider</button>
        </div>
    </div>
</form>
