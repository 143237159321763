import jQuery from 'jquery';
import 'popper.js';
import 'bootstrap';

jQuery(function () {
    var height = -1;
    var width = -1;
    var scrolltop = -1;
    var device = -1;

    var body = jQuery('body');

    /*
     * Viewport
     */
    function viewport() {
        var e = window, a = 'inner';
        if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return {width: e[a + 'Width'], height: e[a + 'Height']};
    }

    /*
     * Device
     * 0 : mobile
     * 1 : tablette
     : 2 : desktop
     */
    function which_device() {
        if (width > 767) {
            if (width > 991) {
                return 2;
            } else {
                return 1;
            }
        }
        return 0;
    }

    /*
     * Resize
     */
    function onresize() {
        width = viewport().width;
        height = viewport().height;

        if (device != which_device()) {
            device = which_device();
        }
    }

    /*
     * sticky_header
     */
    function sticky_header() {
        if (jQuery(window).scrollTop() > 0)
            body.addClass('oct-header-fixed');
        else
            body.removeClass('oct-header-fixed');
    }

    /*
     * Code
     */
    var timeoutResize;
    jQuery(window).resize(function () {
        clearTimeout(timeoutResize);
        timeoutResize = setTimeout(function () {
            onresize();
            sticky_header();
        }, 100);
    });

    jQuery(window).scroll(function () {
        sticky_header();
    });

    jQuery('#oct-header-navigation-toggle').on('click', function () {
        body.toggleClass('oct-navigation-visible');
        sticky_header();
    });

    onresize();
    sticky_header();

    // Restricts input for each element in the set of matched elements to the given inputFilter.
    $.fn.inputFilter = function(inputFilter) {
        return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
        });
    };

    /*
     * Affichage du burger menu et login/logout
     */
    var burgerMenuFront = document.getElementById('oct-header-burger-menu-toggle');
    if (burgerMenuFront !== null) {
        let iconBurgerMenu = burgerMenuFront.querySelector('.oct-header__picto-burger-menu');

        jQuery(burgerMenuFront).on('click', function () {
            jQuery(this).find('.oct_header__sub-menu').toggle();
        });

        let subMenuFront = document.getElementById('oct_header__sub-menu_container');
        body.on('click', function (e) {
            if (e.target !== burgerMenuFront && e.target !== iconBurgerMenu && !subMenuFront.contains(e.target)) {
                jQuery(subMenuFront).hide();
            }
        });
    }

    var logUserFront = document.getElementById('oct-header-log-toggle');
    if (logUserFront !== null) {
        let iconUserMenu = logUserFront.querySelector('.oct-header__picto-home');

        jQuery(logUserFront).on('click', function () {
            jQuery(this).find('.oct_header__log').toggle();
        });

        let logMenuFront = document.getElementById('oct_header__log_container');
        body.on('click', function (e) {
            if (e.target !== logUserFront && e.target !== iconUserMenu && !logMenuFront.contains(e.target)) {
                jQuery(logMenuFront).hide();
            }
        });
    }

    /*
     * Champs de formulaire
     */

    //Vérification que l'on ne saisie que des chiffres
    jQuery('input[name="siren"]').on('keyup',function(e) {
        if (e.which >= 37 && e.which <= 40) {
            return;
        }

        $(this).val(function(index, value) {
            return value.replace(/\D/g, "");
        });
    });
});
