<script>
    import Modal from 'lib/Modal.svelte';

    let modal;

    function close() {
        modal.close();
        document.querySelector('input').focus();
    }
</script>


<Modal title="" cancelText={null} submitText={null} isOpen={true}
       classes={{"ui-dialog": "welcome-dialog"}} width={400}
       bind:this={modal}>

    <p>Bienvenue dans l'aventure Express-Online !</p>

    <p>Votre inscription durera 5 à 10 minutes. Si vous souhaitez l'interrompre
    en cours de route (à partir de l'étape 3), <strong>vous pourrez la
    reprendre à tout moment pendant 7 jours</strong>.</p>

    <p>A cet effet un e-mail vous sera envoyé avec les informations nécessaires
    à la reprise de votre inscription.</p>

    <button class="button" on:click={close}>COMMENCER MON INSCRIPTION</button>
</Modal>
