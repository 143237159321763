<script>
    import Modal from 'lib/Modal.svelte';

    export let type = 'button';
    export let disabled = false;
    export let title = null;
    export let href = null;
    export let text = 'Poursuivre mon inscription';

    const buttonClass = 'button right next';

    // Modal
    let isOpen = false;
    const classes = {"ui-dialog-titlebar": "display-none"};

    function click() {
        window.location.href = href;
    }

    function openModal() {
        isOpen = true;
    }

    function logout() {
        window.location.href = '/accounts/logout/';
    }
</script>

<style>
    .logout {
        cursor: pointer;
    }
</style>

<div class="right">
    {#if disabled}
        <button {type} {title} class={buttonClass} disabled>{text}</button>
    {:else if href}
        <button {type} {title} class={buttonClass} on:click={click}>{text}</button>
    {:else}
        <button {type} {title} class={buttonClass}>{text}</button>
    {/if}

    <slot></slot>

    <br>
    <span on:click={openModal} class="orange small logout">
        Me déconnecter et finir mon inscription plus tard
    </span>
</div>


<Modal bind:isOpen {classes} submitText="OK" width={400} on:submit={logout}>
    <p>Vous pouvez quitter votre inscription à tout moment puis y revenir dans
    un délai maximum de 7 jours afin de télécharger les documents
    manquants.</p>

    <p>Il vous suffira de revenir sur la page d’accueil du site
    https://express-online.com et de cliquer sur « MON INSCRIPTION TRANSPORTEUR »
    (Notez bien votre identifiant et votre mot de passe)</p>
</Modal>
