<script>
    import * as http from 'lib/http.js';
    import Input from './Input.svelte';

    export let data = {}, name;
    export let prefix = 'id';

    function validate(value) {
        return http.post('/api/check/password/', {password: value}).then((json) => {
            return false;
        }, (error) => {
            return (error.status == 400) ? error.payload.password[0] : 'Network error';
        });
    }
</script>

<Input {prefix} {name} type="password" {validate} bind:data />
