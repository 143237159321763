import '@fortawesome/fontawesome-free/css/all.css';
//import "bootstrap/scss/bootstrap.scss";
import './css/main.scss';

import Company from './company.svelte';
import Vehicles from './vehicles.svelte';
import Drivers from './drivers.svelte';
import Documents from './documents.svelte';

import './auto.js'; // For side-effects only

export default {
    Company,
    Vehicles,
    Drivers,
    Documents,
};


window.addEventListener('load', function() {
    let input = document.querySelector('#anchor input');
    if (input) {
        input.focus();
    }
})
