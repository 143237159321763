<script>
    import {createEventDispatcher} from 'svelte';
    import {handleServerErrors} from 'lib/utils.js';
    import * as http from 'lib/http.js';

    const dispatch = createEventDispatcher();
    const url = '/api/my/documents/';

    export let items = [];
    export let type;
    export let status;
    export let vehicle = null;
    export let ok = false;

    let error = null;

    let documents = items.filter(
        item => (item.type == type) && (type != 'grise' || vehicle.id == item.vehicle)
    );

    $: ok = documents.length > 0;

    function filename(item) {
        return item.file.split('/').pop();
    }

    function upload(ev) {
        let input = ev.target;

        let formData = new FormData();
        formData.append("file", input.files[0]);
        formData.append("type", input.dataset.type);
        if (vehicle) {
            formData.append("vehicle", vehicle.id);
        }

        error = null;
        http.post(url, formData, null).then((json) => {
            documents = [...documents, json];
        }, (http_error) => {
            let data = {file: {}};
            let non_field_errors = handleServerErrors(http_error, data);
            error = data.file.error;
        });
    }

    function getPicto(item) {
        switch (item.status) {
            case 'pending':
                return 'picto picto-attente';
            case 'valid':
                return 'picto picto-validation';
            default:
                return 'picto picto-echec';
        }
    }

    function remove(item) {
        let id = item.id;
        http.delete_(url + id + '/').then((response) => {
            documents = documents.filter(x => x.id != id);
        }, (error) => {
            console.log('ERROR');
        });
    }
</script>

<style>
    .picto {
        word-break: break-all;
    }
</style>

<div class="oct-form-content">
    <div class="table-justificatif__container">
        <table class="table-justificatif">
            {#each documents as item}
            <tr>
                <td>
                    <p class={getPicto(item)}>{filename(item)}</p>
                    {#if status == 'draft'}
                        <span class="picto-trash" on:click={() => remove(item)}>
                            <img src="/static/public/img/picto-trash.png" alt="Picto trash">
                        </span>
                    {/if}
                    <a class="picto-apercu" href="{item.file}" target="_blank" rel="noreferrer">
                        <img src="/static/public/img/picto-apercu.png" alt="Picto apercu">
                    </a>
                </td>
            </tr>
            {/each}
        </table>
    </div>
    <label class="oct-custom-file-input">
        <input type="file" on:change={upload} data-type={type}>
    </label>
    {#if error}<span class="oct-info-error">{error}</span>{/if}
</div>
